import {AppPortal, CoreConfig, EmployeeEntityService, SessionRole} from "shared-modules";

export const coreConfig: CoreConfig = {
  portal: AppPortal.Survey,
  navigationMenu: [
    {
      roleIds: [
        SessionRole.User,
        SessionRole.Administrator,
        SessionRole.Superadmin,
      ],
      menu: [
        {
          label: 'Employees',
          route: 'employees',
          icon: 'badge',
        },
        {
          label: 'Surveys',
          route: 'surveys',
          icon: 'quiz',
        },
      ],
    },
    {
      title: 'Admin Menu',
      roleIds: [
        SessionRole.Administrator,
        SessionRole.Superadmin,
      ],
      menu: [
        {
          label: 'Users',
          route: 'users',
          icon: 'people',
        },
      ],
    },
  ],
  userMenu: [],
  resetEntitiesServices: [
    EmployeeEntityService,
  ],
};
