import {Injectable} from "@angular/core";
import {EntityStoreBaseService} from "shared-modules";
import {EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Survey} from "../../models/survey";

@Injectable({providedIn: 'root'})
export class SurveyEntityService extends EntityStoreBaseService<Survey> {
  constructor(serviceFactory: EntityCollectionServiceElementsFactory) {
    super('Survey', serviceFactory);
  }
}
