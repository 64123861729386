import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  adminGuard,
  EmployeeEntityService,
  entityCollectionResolver,
  initializeGuard,
  LayoutComponent,
  sessionGuard, SessionRole
} from "shared-modules";
import {authGuard, LoginComponent} from "auth";
import {ThemedBackgroundComponent} from "caig-utils";
import {SurveyEntityService} from "./services/entity-data/survey-entity.service";
import {authConfig} from "./auth.config";

const routes: Routes = [
  {
    path: '',
    canActivate: [initializeGuard],
    children: [
      {
        path: '',
        canActivate: [authGuard(true), sessionGuard],
        children: [
          {
            path: '',
            component: LayoutComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/surveys',
              },
              {
                path: 'employees',
                resolve: {
                  entities: entityCollectionResolver(EmployeeEntityService),
                },
                loadChildren: () => import('shared-modules').then(m => m.EmployeesModule),
              },
              {
                path: 'surveys',
                resolve: {
                  entities: entityCollectionResolver(SurveyEntityService),
                },
                loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
              },
              {
                path: 'users',
                loadChildren: () => import('shared-modules').then(m => m.UsersModule),
                canActivate: [adminGuard(SessionRole.Administrator)],
              },
              {
                path: 'profile',
                loadChildren: () => import('shared-modules').then(m => m.ProfileModule),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: authConfig.loginRoute,
    component: ThemedBackgroundComponent,
    canActivate: [authGuard(false)],
    children: [
      {
        path: '',
        component: LoginComponent,
      }
    ]
  },
  { path: '**', loadChildren: () => import('shared-modules').then(m => m.PageNotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
