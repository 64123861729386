import {EntityMetadataMap} from '@ngrx/data';
import {sortById} from "caig-utils";

export const entityMetadata: EntityMetadataMap = {
  Employee: {
    sortComparer: sortById,
  },
  User: {},
  SettlementUser: {},
  Survey: {},
};
